import React, { useContext, useEffect, useState } from "react"
import { navigate, graphql } from "gatsby"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import {
  Header,
  Footer,
  PlatePackTable,
  DCXButton,
  Intro,
  OptionFlat,
  CheckoutHeader,
} from "../../components"
import { CarContext } from "../../context"
import {
  CalculatePriceFull,
  urlWithSession,
  removeSaleFromUrl,
  getVersionName,
  getSelectedOptions,
  getSelectedModel,
} from "../../utils"
import { checkoutPlateImage } from "../../assets"
import { URLS } from "../../constants"
import {
  useSelectedAccessoriesTotalPrice,
  useSurchargedPrices,
  useIncludeTaxes,
} from "../../hooks"

export default function CheckoutStep1({ data }) {
  const appContext = useContext(CarContext)
  const {
    appLoading,
    selectedVersion,
    checkoutData,
    setCheckoutData,
    savePlatePlack,
    offers,
    selectedOffer,
    color: colorId,
  } = appContext
  // To control dialog
  const [dialogOpen, setDialogOpen] = useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)

  // Get all models
  const allModels = data.allModels.nodes

  // Check context to determine if sale is present
  const offer = offers?.find(of => of.internalId === selectedOffer)

  // Redirect to home if no version nor offer was detected
  useEffect(() => {
    if (appLoading) return
    if (!selectedVersion && !offer) {
      navigate(removeSaleFromUrl(urlWithSession("/home")))
    }
  }, [selectedVersion, offer])

  // Set tax page visited in context
  useEffect(() => {
    setCheckoutData(prev => ({
      ...prev,
      platePackPageVisited: true,
    }))
  }, [setCheckoutData])

  const selectedModel = getSelectedModel(allModels, selectedVersion)

  // Calculate selected accessories total price
  const [accessoriesTotalPrice] = useSelectedAccessoriesTotalPrice()
  const [prices] = useSurchargedPrices({
    prices: offer
      ? {
          priceList: offer.priceList,
          priceCash: offer.priceCash,
          priceCredit: offer.priceCredit,
        }
      : selectedVersion,
    colorId,
  })
  const [includeTaxes] = useIncludeTaxes()

  const withoutPackFinancing = CalculatePriceFull({
    appContext,
    offer,
    prices,
    aditionals: [accessoriesTotalPrice],
  })

  // Define selectable options
  const options = [
    {
      info: { main: "con PACK", secondary: "PATENTE" },
      selected: checkoutData.includePlatePack,
      minFin: CalculatePriceFull({
        appContext,
        offer,
        prices,
        includeTaxes: includeTaxes,
        aditionals: [accessoriesTotalPrice],
      }),
    },
    {
      info: { main: "sin PACK", secondary: "PATENTE" },
      selected: !checkoutData.includePlatePack,
      disabled: [1, 4].includes(Math.floor(withoutPackFinancing.variant)),
      disabledTooltipText: "Disponible solo para pago al contado",
      minFin: withoutPackFinancing,
    },
  ]

  const modelName = offer ? offer.modelName : selectedModel?.name
  const versionName = offer
    ? offer.versionName
    : getVersionName(selectedModel, selectedVersion?.versionId)
  const colorName = offer
    ? offer.colorName
    : getSelectedOptions(selectedModel, { color: appContext.color }).color
        ?.colorSpanish

  return (
    <>
      <Header />
      <CheckoutHeader />
      <div className="checkout-tax standard-page">
        <div className="content">
          <div className="page-title">Adicionales</div>
          <Intro
            image={checkoutPlateImage}
            title="¿Quieres que nos encarguemos de estos trámites por ti?"
            subtitle="Agrega el pack patente y olvídate"
          />
          <PlatePackTable prices={prices} />

          <div className="page-title">Agrega el pack</div>
          <div className="car-name">
            {modelName} {versionName}, {colorName}
          </div>
          <div className="options">
            {options.map((opt, idx) => (
              <OptionFlat
                key={idx}
                option={opt}
                onClick={() => savePlatePlack({ includePlatePack: !idx })}
              />
            ))}
          </div>
          <p className="disclaimer">
            El pago de la patente y los impuestos de tu auto podrás realizarlo
            con nosotros o de forma particular, pero es un trámite obligatorio.
            Ver{" "}
            <a
              href={URLS.termsAndConditions}
              target="_blank"
              rel="noopener noreferrer"
            >
              Términos y condiciones
            </a>
          </p>
        </div>
        <div className="buttons">
          <DCXButton
            type="secondary"
            text="ATRÁS"
            onClick={() => {
              navigate(urlWithSession("/checkout/accessories"))
            }}
          />
          <DCXButton
            type="primary"
            text="SIGUIENTE"
            onClick={() => {
              if (
                !checkoutData.includePlatePack &&
                !checkoutData.platePackUnderstanding
              )
                openDialog()
              else navigate(urlWithSession("/checkout"))
            }}
          />
        </div>
      </div>
      <Footer />
      <Dialog
        className="tax-page-dialog"
        onClose={closeDialog}
        aria-labelledby="simple-dialog-title"
        open={dialogOpen}
      >
        <DialogTitle id="simple-dialog-title">Patente e impuestos</DialogTitle>
        <DialogContent>
          <div>
            Si no agregas el pack patente, deberás encargarte de pagar los
            trámites.
          </div>
          <div>
            Pagar la inscripción, el permiso de circulación, el SOAP y el
            impuesto verde es obligatorio para poder utilizar tu auto.
          </div>
        </DialogContent>
        <DialogActions>
          <DCXButton
            className="understand-button"
            type="tertiary"
            text="ENTIENDO Y ACEPTO"
            onClick={() => {
              setCheckoutData(prev => ({
                ...prev,
                platePackUnderstanding: true,
              }))
              navigate(urlWithSession("/checkout"))
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

export const query = graphql`
  query BM_BV_CO_QUERY_3 {
    allModels {
      nodes {
        ...ModelBasic
        versions {
          ...VersionBasic
        }
        ...ConfigOptions
      }
    }
  }
`
